* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100vw;
    max-width: 100%;
}

#root {
    height: 100%;
    max-width: 100%;
}

.App {
    height: 100%;
    width: 100%;
    max-width: 100%;
}

.nav-container {
    width: 18%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
}

.navbar {
    height: 100%;
}

.navbar-brand {
    margin-right: 0 !important;
}

.nav-pic {
    width: 65%;
    aspect-ratio: 1;
    border-radius: 50%;
}

.icon {
    font-size: 1.5em;
    color: white;
}

.main {
    margin-left: 18%;
    height: 100%;
}

.section {
    padding: 30px 50px 50px 50px;
    box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
}

.ms-6 {
    margin-left: 70px;
}

.mt-6 {
    margin-top: 70px;
}