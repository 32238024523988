.profile-pic {
    width: 350px;
}

.top {
    padding: 50px;
}

.skill-icon {
    width: 30px;
    height: 30px;
}

.timeline {
    position: relative;
}

.timeline-a {
    text-decoration: none;
}

.timeline-a:hover .timeline-container {
    background-color: #f6f6f6 !important;
}

.timeline-left .timeline-row {
    display: flex;
    justify-content: start;
}

.timeline-right .timeline-row {
    display: flex;
    justify-content: end;
}

.timeline-left .timeline-container {
    margin-left: 35px;
}

.timeline-right .timeline-container {
    margin-right: 35px;
}

.timeline-row {
    position: relative;
}

.timeline-container {
    position: relative;
}

.dot {
    position: absolute;
    top: 50%;
    width: 25px;
    height: 25px;
    background-color: white;
    border: 4px solid #45d821;
    border-radius: 50%;
    z-index: 2;
}

.timeline-left .dot {
    transform: translate(-50%, -50%);
    left: 0;
}

.timeline-right .dot {
    right: 0;
    transform: translate(50%, -50%);
}

.v-line {
    position: absolute;
    width: 1px;
    background-color: rgb(212, 212, 212);
    top: 0;
    bottom: 0;
    z-index: 1;
}

.timeline-left .v-line {
    left: 0;
}

.timeline-right .v-line {
    right: 0;
}

.timeline-row:first-child .v-line {
    top: 50%;
}

.timeline-row:last-child .v-line {
    bottom: 50%;
}

.arrow {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    line-height: 0;
    font-size: 0;
    background: white;
}

.timeline-left .arrow {
    left: 0;
    border-width: 0 0 3px 3px !important;
    transform: translateX(-50%) rotate(45deg);
}

.timeline-right .arrow {
    right: 0;
    border-width: 3px 3px 0 0 !important;
    transform: translateX(50%) rotate(45deg);
}

/*
.timeline-left::before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: rgb(212, 212, 212);
    top: 15px;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    z-index: 1;
}

.timeline-right::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: rgb(212, 212, 212);
    top: 15px;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
    z-index: 1;
}*/

.box-shadow {
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.exp-pic {
    width: 150px;
    height: auto;
}

.exp-pic-lg {
    width: 600px;
    height: auto;
}

.responsive-flex-column {
    flex-direction: column;
}


@media screen and (max-width: 1400px) {
    .education-card-header {
        display: block !important;
    }

    .education-school {
        margin-left: 0 !important;
        margin-top: 10px !important;
    }

    .exp-pic-lg {
        width: 400px !important;
        height: auto !important;
    }

    .award-pic {
        width: 250px !important;
        height: auto !important;
    }
}

@media screen and (max-width: 1200px) {
    .timeline {
        width: 100% !important;
    }
}

@media screen and (max-width: 992px) {
    .profile-pic {
        width: 300px;
    }

    .details {
        align-items: center !important;
        margin-left: 0;
        margin-top: 20px;
    }

    .top {
        flex-direction: column;
        margin-top: 30px;
    }

    .timeline {
        width: 100% !important;
    }

    .responsive-flex-column {
        display: flex;
        flex-direction: row;
    }

    .nav-pic {
        width: 25%;
    }

    .nav-container {
        height: auto;
        width: 100%;
    }

    .main {
        margin-top: 56px;
    }

    .award-pic {
        margin-top: 40px;
    }

    .clear-pad-x {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .main {
        margin-left: 0;
    }

    .exp-details {
        text-align: center;
        margin-top: 15px;
    }

    .skill-row {
        justify-content: center;
    }

    .exp2-details {
        text-align: center;
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    .md-text-center {
        text-align: center !important;
    }

    .md-align-items-center {
        align-items: center !important;
    }
}

@media screen and (max-width: 576px) {
    .exp-pic-lg {
        width: 300px !important;
        height: auto !important;
    }

    .section {
        padding: 25px;
    }

    .clear-text-align {
        text-align: center !important;
    }

    .dot,
    .v-line,
    .timeline-left::before,
    .timeline-right::after {
        display: none;
    }

    .timeline-container {
        margin: 0 !important;
        text-align: center;
    }
}